import { create } from 'zustand'
import { persist } from 'zustand/middleware'

const useNavigationToggle = create()(
  persist(
    (set) => ({
      sidebarOpen: true,
      setSidebarOpen: () => set((state) => ({ sidebarOpen: !state.sidebarOpen })),
    }),
    {
      name: 'sidebar-navigation-toggle',
    }
  )
)

export default useNavigationToggle
