import { create } from 'zustand'

import securedApi from 'backend/axios'
import Notify from 'components/Notification'
import { processGetError } from 'helpers/utils'
import { MONTH } from 'helpers/planner'

import { EmojiDisplay } from 'components/Emoji/Elements'

/*
  This hook is used to store the user's preferences. It is called in the App component to fetch the user's
  preferences on page load. It is then called within the application to get/set the user's preferences.
*/

const useUserPreferences = create((set, get) => ({
  compactView: false,
  emojiSkinTone: 'none',
  timeZone: 'Australia/Sydney',
  userName: '',
  userEmail: '',
  plannerView: MONTH,
  isCoach: false,
  fetch: async () => {
    return securedApi
      .get('accounts/get_preferences')
      .then(({ data }) => {
        set({
          compactView: data.compact_view,
          emojiSkinTone: data.emoji_skin_tone,
          timeZone: data.time_zone,
          userName: data.user_name,
          userEmail: data.user_email,
          plannerView: data.planner_view,
          isCoach: data.is_coach,
        })
      })
      .catch((error) => {
        processGetError(error)
      })
  },
  setPlannerView: (value) => {
    //Optimistic update to avoid waiting for the API call
    set(() => ({ plannerView: value }))
    securedApi.post(`/accounts/set_preference?account[planner_view]=${value}`).catch((error) => {
      processGetError(error)
    })
  },
  toggleCompactView: () => {
    const currentCompactViewState = get().compactView

    //Optimistic update to avoid waiting for the API call
    set(() => ({ compactView: !currentCompactViewState }))

    securedApi
      .post(`/accounts/set_preference?account[compact_view]=${!currentCompactViewState}`)
      .then(() => {})
      .catch((error) => {
        processGetError(error)
      })
  },
  setUserName: (value) => {
    securedApi
      .post(`/accounts/update?user[name]=${value}`)
      .then(() => {
        set(() => ({ userName: value }))
        Notify({ content: 'Name updated', type: 'success' })
      })
      .catch((error) => {
        processGetError(error)
      })
  },
  setTimeZone: (value) => {
    securedApi
      .post(`/accounts/set_preference?account[time_zone]=${value}`)
      .then(() => {
        set(() => ({ timeZone: value }))
        Notify({ content: 'Time Zone updated', type: 'success' })
      })
      .catch((error) => {
        processGetError(error)
      })
  },
  setEmojiSkinTone: (value) => {
    securedApi
      .post(`/accounts/set_preference?account[emoji_skin_tone]=${value}`)
      .then(() => {
        set(() => ({ emojiSkinTone: value }))
        Notify({
          content: <>Emoji updated {<EmojiDisplay lookup={value} />}</>,
          type: 'success',
        })
      })
      .catch((error) => {
        processGetError(error)
      })
  },
}))

export default useUserPreferences
