import React from 'react'
import {
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  Sidebar,
  ZoomIn,
  ZoomOut,
} from 'react-feather'
import { Box, Button, HStack, Icon, Select, Spacer, Text } from '@chakra-ui/react'
import { eachDayOfInterval, endOfMonth, format, parse, endOfWeek, startOfWeek } from 'date-fns'

import { globalBackgroundColour } from 'theme'

import {
  useSidebarToggle,
  useIsMobile,
  useUserPreferences,
  useBulkAddWorkout,
  useActivityModal,
  usePlannerNote,
} from 'hooks'

import { usePlannerDate, usePlannerMethods } from './hooks'

import { WEEK, MONTH } from 'helpers/planner'

import { useNavigationToggle } from 'domain/Navigation/hooks'

import ActivityModal from 'domain/Planner/components/ActivityModal'
import PlannerNote from 'domain/Notes/PlannerNote'

import BulkAddWorkout from './DayActions/BulkAddWorkout/BulkAddWorkout'

import MobileView from './MobileView'
import WeekView from './WeekView'
import MonthView from './MonthView'

const Planner = () => {
  const { isMobile } = useIsMobile()

  const { refreshData } = usePlannerMethods()

  const compactView = useUserPreferences((state) => state.compactView)
  const toggleCompactView = useUserPreferences((state) => state.toggleCompactView)

  const plannerView = useUserPreferences((state) => state.plannerView)
  const setPlannerView = useUserPreferences((state) => state.setPlannerView)

  const bulkAddWorkoutOpen = useBulkAddWorkout((state) => state.bulkAddWorkoutOpen)

  const activityModalOpen = useActivityModal((state) => state.activityModalOpen)
  const setActivityModalClose = useActivityModal((state) => state.setActivityModalClose)
  const activityModalWorkoutId = useActivityModal((state) => state.activityModalWorkoutId)

  const plannerNoteOpen = usePlannerNote((state) => state.plannerNoteOpen)
  const setPlannerNoteClose = usePlannerNote((state) => state.setPlannerNoteClose)
  const plannerNoteId = usePlannerNote((state) => state.plannerNoteId)

  const setSidebarOpen = useSidebarToggle((state) => state.setSidebarOpen)

  const setToday = usePlannerDate((state) => state.setToday)
  const setPrevWeek = usePlannerDate((state) => state.setPrevWeek)
  const setNextWeek = usePlannerDate((state) => state.setNextWeek)
  const setPrevMonth = usePlannerDate((state) => state.setPrevMonth)
  const setNextMonth = usePlannerDate((state) => state.setNextMonth)

  const currentWeek = usePlannerDate((state) => state.currentWeek)
  const currentMonth = usePlannerDate((state) => state.currentMonth)

  const navigationSidebarOpen = useNavigationToggle((state) => state.sidebarOpen)

  const daysOfWeek = eachDayOfInterval({
    start: currentWeek,
    end: endOfWeek(currentWeek, { weekStartsOn: 1 }),
  })

  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  const daysOfMonth = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth, { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
  })

  const plannerWidth = navigationSidebarOpen ? '186px' : '66px'

  const handleSetView = (event) => {
    setPlannerView(event.target.value)
  }

  //maybe this can be moved
  const handleCloseNote = () => {
    setPlannerNoteClose()
    refreshData()
  }

  const renderCalendarNavigation = () => {
    if (plannerView === WEEK) {
      return (
        <>
          <Button colorScheme="brand" variant="outline" onClick={setPrevWeek}>
            <ChevronLeft size="20px" />
          </Button>
          <Button colorScheme="brand" variant="outline" onClick={setNextWeek}>
            <ChevronRight size="20px" />
          </Button>
        </>
      )
    }

    return (
      <>
        <Button colorScheme="brand" variant="outline" onClick={setPrevMonth}>
          <ChevronUp size="20px" />
        </Button>
        <Button colorScheme="brand" variant="outline" onClick={setNextMonth}>
          <ChevronDown size="20px" />
        </Button>
      </>
    )
  }

  if (isMobile) {
    return (
      <>
        <MobileView {...{ daysOfWeek }} />
        {bulkAddWorkoutOpen && <BulkAddWorkout />}
        {activityModalOpen && (
          <ActivityModal
            id={activityModalWorkoutId}
            isOpen={activityModalOpen}
            onClose={setActivityModalClose}
          />
        )}
        {plannerNoteOpen && (
          <PlannerNote
            noteId={plannerNoteId}
            open={plannerNoteOpen}
            onClose={() => handleCloseNote()}
            getAndSetNewDayData={() => handleCloseNote()}
          />
        )}
      </>
    )
  }

  return (
    <>
      <Box
        display="flex"
        position="fixed"
        w={`calc(100% - ${plannerWidth})`} // 220px is the width of the sidebar
        zIndex={2}
        paddingX="4"
        paddingY="2"
        background={globalBackgroundColour}
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        <Box display="flex" alignItems="center">
          <Text as="span" fontWeight="bold">
            {format(firstDayCurrentMonth, 'MMMM yyyy')}
          </Text>
        </Box>
        <Spacer />
        <HStack spacing="24px">
          <Box cursor="pointer" height="20px" onClick={() => toggleCompactView()}>
            {compactView ? (
              <Icon
                as={ZoomIn}
                w="5"
                h="5"
                color="gray.500"
                strokeWidth="1px"
                _hover={{ cursor: 'pointer', color: 'brand.500' }}
              />
            ) : (
              <Icon
                as={ZoomOut}
                w="5"
                h="5"
                color="gray.500"
                strokeWidth="1px"
                _hover={{ cursor: 'pointer', color: 'brand.500' }}
              />
            )}
          </Box>
          {plannerView === MONTH && (
            <>
              {/* <Box cursor="pointer" onClick={() => setSidebarOpen()}>
                <Icon
                  as={Sidebar}
                  w="4"
                  h="4"
                  color="gray.500"
                  strokeWidth="1px"
                  _hover={{ cursor: "pointer", color: "brand.500" }}
                />
              </Box> */}
            </>
          )}

          <Select maxWidth="120px" value={plannerView} onChange={handleSetView} variant="outline">
            <option value={WEEK}>Week</option>
            <option value={MONTH}>Month</option>
          </Select>

          <Button colorScheme="brand" variant="outline" onClick={setToday}>
            Today
          </Button>

          {renderCalendarNavigation()}
        </HStack>
      </Box>
      {/* 66px is the height of the top bar */}
      <Box m="4" mt="66px">
        {plannerView === WEEK && <WeekView {...{ daysOfWeek }} />}
        {plannerView === MONTH && <MonthView {...{ daysOfMonth, firstDayCurrentMonth }} />}
        {bulkAddWorkoutOpen && <BulkAddWorkout />}
        {activityModalOpen && (
          <ActivityModal
            id={activityModalWorkoutId}
            isOpen={activityModalOpen}
            onClose={setActivityModalClose}
          />
        )}
        {plannerNoteOpen && (
          <PlannerNote
            noteId={plannerNoteId}
            open={plannerNoteOpen}
            onClose={() => handleCloseNote()}
            getAndSetNewDayData={() => handleCloseNote()}
          />
        )}
      </Box>
    </>
  )
}

export default Planner
