import { NavLink } from 'react-router-dom'
import { Link, MenuItem, Tooltip } from '@chakra-ui/react'
import { useNavigationToggle } from 'domain/Navigation/hooks'

import NavigationIcons from 'domain/Navigation/Components/NavigationIcons'

export const NavItem = ({ to, title, fontSize = 'base', icon }) => {
  const sidebarOpen = useNavigationToggle((state) => state.sidebarOpen)
  return (
    <Tooltip label={!sidebarOpen && title}>
      <Link
        as={NavLink}
        display="flex"
        alignItems="start"
        color="gray.500"
        _activeLink={{ color: 'black' }}
        _hover={{ textDecoration: 'none', color: 'brand.500' }}
        width="100%"
        end
        {...{ fontSize, to }}
      >
        {icon && <NavigationIcons icon={icon} />}

        {sidebarOpen && title}
      </Link>
    </Tooltip>
  )
}

export const MenuLink = ({ to, title }) => {
  return (
    <MenuItem as={NavLink} to={to} _activeLink={{ color: 'black' }} p="2">
      {title}
    </MenuItem>
  )
}
