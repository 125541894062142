import React from 'react'
import { Box, Stack, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react'

import { useQuery } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'
import Error from 'components/General/Error'

const Reports = () => {
  const { data, isLoading, isValidating, hasError } = useQuery('/accounts/reports')

  if (hasError) return <Error />

  if (isLoading || isValidating) {
    return <LoadingSpinner />
  }

  return (
    <Stack spacing="10">
      <Box>
        <Text fontWeight="bold">Reports</Text>
      </Box>
      <Box>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>user_id</Th>
              <Th>user_email</Th>
              <Th>user_created_at</Th>
              {/*<Th>sub_id</Th>*/}
              <Th>sub_plan</Th>
              <Th>sub_status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((d) => {
              const { user_id, user_email, user_created_at, sub_id, sub_plan, sub_status } = d
             
              return (
                <Tr>
                  <Td>{user_id}</Td>
                  <Td>{user_email}</Td>
                  <Td>{user_created_at}</Td>
                  {/*<Td>{sub_id}</Td>*/}
                  <Td>{sub_plan}</Td>
                  <Td>{sub_status}</Td>
                </Tr>
              )
             })}
          </Tbody>
        </Table>
      </Box>
    </Stack>
  )
}

export default Reports


            // {data.map((d) => {
            //   return (
            //     <Tr>
            //       <Td>{data[0]}</Td>
            //       <Td>{data[1]}</Td>
            //     </Tr>
            //     )
            //   })}