import { NavLink } from 'react-router-dom'
import { Box, Stack, StackDivider, Button, Flex, Text, Link, HStack } from '@chakra-ui/react'

import { useImpersonate, useCoaching, useIsMobile, useUserPreferences } from 'hooks'

import { useSignOut, useDisplayUpgrade, useNavigationToggle } from 'domain/Navigation/hooks'

import UpgradeButton from 'components/General/UpgradeButton'
import Dropdown from 'components/General/Dropdown'

import { NavItem } from 'domain/Navigation/Components/Links'
import { MainNavigation, SecondaryNavigation } from 'domain/Navigation/Components/DesktopNavigation'
import {
  MainMobileNavigation,
  SecondaryMobileNavigation,
} from 'domain/Navigation/Components/MobileNavigation'
import Logo from 'domain/Navigation/Components/Logo'
import CoachingModeToggle from 'domain/Navigation/CoachingModeToggle'
import AdminBanner from 'domain/Navigation/AdminBanner'
import { getLogoURL } from 'domain/Navigation/helpers'

const Navigation = () => {
  const { isMobile } = useIsMobile()
  const { signOut } = useSignOut()
  const impersonateData = useImpersonate((state) => state.impersonateData)
  const isCoachingInterfaceEnabled = useCoaching((state) => state.isCoachingInterfaceEnabled)
  const { displayUpgrade } = useDisplayUpgrade()
  const sidebarOpen = useNavigationToggle((state) => state.sidebarOpen)
  const setSidebarOpen = useNavigationToggle((state) => state.setSidebarOpen)
  const isCoach = useUserPreferences((state) => state.isCoach)

  if (isMobile) {
    return (
      <Flex
        alignItems="center"
        justifyContent="space-between"
        px="6"
        py="2"
        borderBottom="1px"
        borderColor="gray.300"
        position="fixed"
        top="0"
        background="gray.50"
        width="100%"
        zIndex="20"
      >
        <MainMobileNavigation />
        <Box alignItems="center">
          <Link as={NavLink} to={getLogoURL(isCoachingInterfaceEnabled)}>
            <Logo />
          </Link>
        </Box>
        <SecondaryMobileNavigation />
      </Flex>
    )
  }

  return (
    <Flex height="100vh" background="gray.50">
      <Stack
        flex="1"
        w={sidebarOpen ? '180px' : '60px'}
        py="8"
        px="6"
        justifyContent="space-between"
        overflow="auto"
      >
        <Stack spacing="8">
          <Stack spacing="3">
            <Link as={NavLink} to={getLogoURL(isCoachingInterfaceEnabled)} mb="2">
              {sidebarOpen ? (
                <Flex alignItems="center">
                  <Logo />

                  <Text
                    fontSize="sm"
                    pt="1"
                    pl="2"
                    color="gray.700"
                    textTransform="uppercase"
                    fontWeight="semibold"
                  >
                    Sequence
                  </Text>
                </Flex>
              ) : (
                <Flex w="25px" ml="-1px">
                  <Logo />
                </Flex>
              )}
            </Link>
            {impersonateData.impersonateAsAdmin && <AdminBanner {...{ impersonateData }} />}
            {!isCoachingInterfaceEnabled ? (
              <>
                <MainNavigation />
                <SecondaryNavigation />
              </>
            ) : (
              <>
                <NavItem to="/coaching" title="Dashboard" icon="dashboard" />
                <NavItem to="/coaching/clients" title="Clients" icon="clients" />
                <NavItem to="/coaching/workouts" title="Coaching Workouts" icon="workouts" />
                <NavItem to="/coaching/templates" title="Templates" icon="templates" />
              </>
            )}
          </Stack>
        </Stack>
        <Stack spacing="4" divider={<StackDivider />}>
          {!sidebarOpen && (
            <Box ml="-12px">
              <Dropdown>
                {displayUpgrade && (
                  <Box p="2" margin="0 auto">
                    <UpgradeButton counter={false} />
                  </Box>
                )}

                {isCoach && (
                  <Box p="2" width="100%">
                    <CoachingModeToggle />
                  </Box>
                )}

                {isCoachingInterfaceEnabled && (
                  <Link
                    as={NavLink}
                    to="/coaching/billing"
                    color="gray.500"
                    fontSize="sm"
                    p="2"
                    width="100%"
                    _hover={{ cursor: 'pointer', color: 'brand.500' }}
                  >
                    Billing
                  </Link>
                )}

                <Link
                  as={NavLink}
                  to="/settings"
                  color="gray.500"
                  fontSize="sm"
                  p="2"
                  width="100%"
                  _hover={{ cursor: 'pointer', color: 'brand.500' }}
                >
                  Settings
                </Link>

                <Text
                  onClick={signOut}
                  color="gray.500"
                  variant="link"
                  fontSize="sm"
                  _hover={{ textDecoration: 'none', color: 'brand.500' }}
                  p="2"
                  width="100%"
                >
                  Sign Out
                </Text>
              </Dropdown>
            </Box>
          )}

          {sidebarOpen && (
            <>
              {displayUpgrade ? <UpgradeButton counter={false} /> : <Box />}

              <Stack spacing="3" alignItems="flex-start" pt="2">
                <CoachingModeToggle />
                {isCoachingInterfaceEnabled && (
                  <NavItem to="/coaching/billing" title="Billing" fontSize="sm" />
                )}
                <NavItem to="/settings" title="Settings" fontSize="sm" />
                <Button
                  onClick={signOut}
                  color="gray.500"
                  variant="link"
                  fontSize="sm"
                  _hover={{ textDecoration: 'none', color: 'brand.500' }}
                >
                  Sign Out
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <HStack borderRightWidth="1px">
        <Box
          onClick={() => setSidebarOpen()}
          cursor="pointer"
          bg="gray.300"
          w="4px"
          h="20px"
          mr="1"
          rounded="full"
          transition={'background-color 0.2s'}
          _hover={{
            cursor: 'pointer',
            bg: 'brand.500',
          }}
        />
      </HStack>
    </Flex>
  )
}

export default Navigation
