import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Box, Button, Center, Stack, HStack, Text } from '@chakra-ui/react'

import securedApi from 'backend/axios'
import { YEARLY_PRICE_ID, MONTHLY_PRICE_ID, CS_MONTHLY_PRICE_ID } from 'helpers/keys'
import { useStateManager } from 'hooks'

import LoadingSpinner from 'components/LoadingSpinner'

const CheckoutForm = () => {
  let navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const priceQuery = queryParams.get('price')
  const { setSubscribed, hideOverlay } = useStateManager()

  const [disabled, setDisabled] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [priceId, setPriceId] = useState(null)

  useEffect(() => {
    if (priceQuery === 'climb-strong-monthly') {
      setPriceId(CS_MONTHLY_PRICE_ID)
    } else {
      setPriceId(MONTHLY_PRICE_ID)
    }
  }, [priceQuery])

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: 'rgb(220 38 38)',
        iconColor: '#fa755a',
      },
    },
  }

  const submitStyle = {
    minHeight: 80,
    height: 80,
  }

  const errorStyle = {
    minHeight: 24,
    color: 'rgb(220 38 38)',
  }

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : '')
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()
    setProcessing(true)

    // Create PaymentIntent when user opens upgrade/pay interface
    const stripeData = await securedApi.post('/payments/subscriptions/create', {
      priceId,
    })

    const { clientSecret, clientSecretType } = stripeData.data

    let payload
    if (clientSecretType === 'payment_intent') {
      payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
    }

    if (clientSecretType === 'pending_setup_intent') {
      payload = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      })
    }

    setProcessing(false)

    if (payload.error) {
      setError(payload.error.message)
    } else {
      setError(null)
      setSucceeded(true)
      hideOverlay()
      setSubscribed(priceId)

      navigate('/subscription-successful')
    }
  }

  return (
    <>
      <Stack spacing="4">
        <HStack spacing="4">
          <Button
            variant={priceId === MONTHLY_PRICE_ID ? 'primary' : 'outline'}
            _hover={{ color: 'white', bg: 'brand.600' }}
            onClick={() => setPriceId(MONTHLY_PRICE_ID)}
          >
            Monthly – $8 / month
          </Button>

          <Button
            variant={priceId === YEARLY_PRICE_ID ? 'primary' : 'outline'}
            _hover={{ color: 'white', bg: 'brand.600' }}
            onClick={() => setPriceId(YEARLY_PRICE_ID)}
          >
            Yearly – $80 / year
          </Button>
        </HStack>

        <HStack justifyContent="center">
          <Button
            variant={priceId === CS_MONTHLY_PRICE_ID ? 'primary' : 'outline'}
            _hover={{ color: 'white', bg: 'brand.600' }}
            onClick={() => setPriceId(CS_MONTHLY_PRICE_ID)}
          >
            Climb Strong – $12 USD / month
          </Button>
        </HStack>
      </Stack>

      <form id="payment-form" onSubmit={handleSubmit}>
        <Box background="#fff" padding="4" marginTop="4">
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        </Box>
        <Center>
          {processing ? (
            <Box style={submitStyle}>
              <LoadingSpinner />
            </Box>
          ) : (
            <Box style={submitStyle}>
              <Button
                disabled={processing || disabled || succeeded}
                id="submit"
                variant="primary"
                marginTop="4"
                marginBottom="4"
                type="submit"
                data-cy="subscribe-button"
              >
                Subscribe
              </Button>
            </Box>
          )}
        </Center>
        <Center>
          <Box style={errorStyle} role="alert">
            <Text>{error}</Text>
          </Box>
        </Center>
      </form>
    </>
  )
}

export default CheckoutForm
