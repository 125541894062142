import React from 'react'
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Stack,
  Switch,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { Info } from 'react-feather'

import { useUserPreferences } from 'hooks'

import { EmojiPicker } from 'components/Emoji/Elements'

import TimezonePicker from './TimezonePicker'

const General = () => {
  const userName = useUserPreferences((state) => state.userName)
  const setUserName = useUserPreferences((state) => state.setUserName)
  const userEmail = useUserPreferences((state) => state.userEmail)
  const compactView = useUserPreferences((state) => state.compactView)
  const toggleCompactView = useUserPreferences((state) => state.toggleCompactView)
  const timeZone = useUserPreferences((state) => state.timeZone)
  const seTimeZone = useUserPreferences((state) => state.setTimeZone)
  const emojiSkinTone = useUserPreferences((state) => state.emojiSkinTone)
  const setEmojiSkinTone = useUserPreferences((state) => state.setEmojiSkinTone)

  const handleUpdateUserName = (event) => {
    const { value } = event.currentTarget

    if (value === userName) {
      return
    }

    setUserName(value)
  }

  const changeTimeZone = (event) => {
    const { value } = event.currentTarget

    if (value === timeZone) {
      return
    }

    seTimeZone(value)
  }

  const changeEmojiSkinTone = (value) => {
    setEmojiSkinTone(value)
  }

  return (
    <Box display="block" maxWidth="400px">
      <Stack spacing="6" width="100%">
        <FormControl>
          <FormLabel htmlFor="user-name">Name</FormLabel>
          <Input
            id="user-name"
            defaultValue={userName}
            placeholder="Enter your name"
            onBlur={(event) => handleUpdateUserName(event)}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="user-email">Email</FormLabel>
          <Text>{userEmail}</Text>
        </FormControl>

        <FormControl>
          <FormLabel as="legend" htmlFor={null}>
            <Box display="flex" alignItems="center">
              Compact calendar view
              <Box marginLeft="2">
                <Tooltip label="You can also toggle this while using the calendar itself with the button in the top-right corner">
                  <Info size="20" />
                </Tooltip>
              </Box>
            </Box>
          </FormLabel>

          <Switch
            id="compact-view"
            colorScheme="yellow"
            isChecked={!compactView}
            onChange={toggleCompactView}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="time-zone">Time zone</FormLabel>
          <TimezonePicker
            id="time-zone"
            placeholder="Select time zone"
            defaultValue={timeZone}
            onChange={changeTimeZone}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="emoji-skin-tone">Emoji theme</FormLabel>
          <EmojiPicker
            id="emoji-skin-tone"
            defaultValue={emojiSkinTone}
            onChange={changeEmojiSkinTone}
          />
        </FormControl>

        <FormControl>
          <FormLabel mb="0">Rituals</FormLabel>
          <FormHelperText mt="0" mb="2">
            Create a recurring calendar event to remind you to plan your training.
          </FormHelperText>
          <Link
            href="https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=N2lodW9mY25sNnZyamZjZXNiMmR2MG5pbTlfMjAyMzAyMTlUMDkzMDAwWiBjX2E2NWM0YWFiMTY0ZWM3ODBiOTk2NWY2Y2VjYmQyM2I4ZmE1YWZkYjVhYjhmMGNlYzI5NTNmNDlmZDY5ZDBmYzhAZw&tmsrc=c_a65c4aab164ec780b9965f6cecbd23b8fa5afdb5ab8f0cec2953f49fd69d0fc8%40group.calendar.google.com&scp=ALL"
            isExternal
            variant="brand"
          >
            Add to Google Calendar
          </Link>
        </FormControl>
      </Stack>
    </Box>
  )
}

export default General
