import { Icon } from '@chakra-ui/react'

import { AiOutlineDashboard, AiOutlineCalendar, AiOutlineBarChart } from 'react-icons/ai'
import { GoProjectRoadmap } from 'react-icons/go'
import { VscTarget } from 'react-icons/vsc'
import { LuClipboardList, LuLayoutTemplate } from 'react-icons/lu'
import { FiLayers, FiUsers } from 'react-icons/fi'

const iconMap = {
  dashboard: AiOutlineDashboard,
  planner: AiOutlineCalendar,
  workouts: FiLayers,
  plans: GoProjectRoadmap,
  metrics: AiOutlineBarChart,
  goals: VscTarget,
  tests: LuClipboardList,
  clients: FiUsers,
  templates: LuLayoutTemplate,
}

const NavigationIcons = ({ icon }) => {
  const IconComponent = iconMap[icon]

  return <Icon as={IconComponent} w={5} mr="2" mt="1" />
}

export default NavigationIcons
