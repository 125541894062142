import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  AspectRatio,
  SimpleGrid,
  Box,
  Container,
  HStack,
  Button,
  Heading,
  Image,
  Stack,
  Text,
  Link as ChakraLink,
  Icon,
  List,
  ListItem,
} from '@chakra-ui/react'

import { FiCheck } from 'react-icons/fi'

import TrainingHero from '../assets/training-hero.jpg'

import ClimbStrongBio from './assets/climb-strong-bio.png'
import ClimbStrongHero from './assets/climb-strong-hero-1.jpeg'
import ClimbStrongHero3 from './assets/climb-strong-hero-3.jpeg'
import ClimbStrongHero4 from './assets/climb-strong-hero-4.jpeg'

const features = [
  'All Climb Strong Training Plans',
  'New Content Added Monthly',
  'Regular Free Updates',
  'Dedicated Customer Support',
  'Limitless Potential',
]

const ClimbStrongCollection = () => {
  return (
    <>
      <Image
        alt="Climb Strong"
        src={ClimbStrongHero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'lg' }}
        width="full"
      />

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Heading size={{ base: '2xl', md: 'xl' }}>Climb Strong Training</Heading>

              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                We build the most effective climbing training plans.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                We build all of our training plans to work for real people. Our plans can be
                assembled to address almost any training goal.
              </Text>

              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                Whether bouldering, big-wall climbing, redpointing, or just getting generally
                stronger, this is your starting point for getting better.
              </Text>
            </Stack>
          </Stack>
          <AspectRatio ratio={1}>
            <Image objectFit="cover" src={ClimbStrongHero4} alt="Climb Strong Plans" />
          </AspectRatio>
        </SimpleGrid>
      </Container>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <AspectRatio ratio={1}>
            <Image objectFit="cover" src={ClimbStrongHero3} alt="More about Climb Strong plans" />
          </AspectRatio>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing={{ base: '4', md: '6' }}>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                We are continually updating and revising and testing new training ideas. We are
                working hard to provide real-world training and performance advice that avoids
                commercial bias. Most importantly, we try to help get quality training advice to
                everyone.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                We work hard to continually learn, test, and improve. Our mission is to make your
                training simpler and more effective than ever before.{' '}
              </Text>
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>

      <Box px={{ base: '4', md: '8' }} py="20">
        <Stack spacing="4" align="center">
          <Stack spacing="4">
            <Stack spacing="3" align="center">
              <Heading size={{ base: 'md', md: 'lg' }} fontWeight="semibold">
                Climb Strong Membership
              </Heading>
            </Stack>
            <Text
              color="fg.muted"
              textStyle={{ base: 'lg', md: 'xl' }}
              maxW="3xl"
              textAlign="center"
            >
              Get access to ....
            </Text>
          </Stack>
          <Box
            bg="white"
            borderWidth="1px"
            borderRadius="2xl"
            boxShadow="sm"
            px={{ base: '6', md: '8' }}
            py="8"
            width="full"
            maxW="lg"
          >
            <Stack spacing="8" textAlign="center">
              <Stack align="center">
                <Text textStyle={{ base: 'md', md: 'lg' }} fontWeight="semibold">
                  Monthly Subscription
                </Text>
                <Heading size={{ base: 'md', md: 'lg' }}>$12</Heading>
              </Stack>
              <List spacing="4">
                {features.map((feature) => (
                  <ListItem key={feature}>
                    <HStack spacing="4">
                      <Icon as={FiCheck} color="brand.500" boxSize={{ base: '4', md: '6' }} />
                      <Text textAlign="left" fontSize={{ base: 'sm', md: 'md' }}>
                        {feature}
                      </Text>
                    </HStack>
                  </ListItem>
                ))}
              </List>

              <Button
                as={NavLink}
                variant="primary"
                _hover={{ textDecoration: 'none', bg: 'brand.600' }}
                to="/subscription-create?price=climb-strong-monthly"
                size="lg"
                fontWeight="semibold"
              >
                Become a Member
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Container py={{ base: '16', md: '24' }} maxWidth="container.lg">
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={16}>
          <Stack spacing={{ base: '8', md: '12' }} justifyContent="center">
            <Stack spacing="3">
              <Heading size={{ base: '2xl', md: 'xl' }}>Climb Strong Bio</Heading>

              <Text color="fg.muted" fontSize={{ base: 'xl', md: '2xl' }} fontWeight="bold">
                Climb Strong was established in 2007. It started as a blog, and then was a blog and
                a book, and then was a website with a discussion forum.
              </Text>
              <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                Over the years, we’ve morphed Climb Strong to continually try to serve the needs of
                the athletes who contact us for advice. We started building template training plans,
                writing strength articles, and filming videos of exercises. In 2007, it was Steve
                Bechtel and a laptop. Now, we have a full time crew working on various aspects of
                our mission and a coaching staff of seven people.
              </Text>

              <ChakraLink
                fontSize="sm"
                href="https://www.climbstrong.com/"
                isExternal
                color="brand.500"
                fontWeight="bold"
              >
                Climb Strong website
              </ChakraLink>
            </Stack>
          </Stack>
          <AspectRatio ratio={1}>
            <Image
              objectFit="cover"
              src={ClimbStrongBio}
              alt="Climb Strong Logo"
              borderRadius="full"
            />
          </AspectRatio>
        </SimpleGrid>

        <Stack pt={{ base: '16', md: '24' }} spacing="3" align="center">
          <Heading size={{ base: 'sm', md: 'md' }}>Do you have any questions?</Heading>
          <HStack>
            <Button
              as={ChakraLink}
              variant="primary"
              _hover={{ textDecoration: 'none', bg: 'brand.600' }}
              href="mailto:help@sequence-app.com?subject=Question%20Training%20Plans"
            >
              Send us an email
            </Button>
          </HStack>
        </Stack>
      </Container>

      <Image
        alt="Training Psych"
        src={TrainingHero}
        objectFit="cover"
        objectPosition="center"
        maxH={{ base: 'sm', md: 'sm' }}
        width="full"
      />
    </>
  )
}

export default ClimbStrongCollection
