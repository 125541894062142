import { NavLink } from 'react-router-dom'
import {
  Box,
  HStack,
  Divider,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  Link,
} from '@chakra-ui/react'
import { ChevronDown } from 'react-feather'

import { useImpersonate } from 'hooks'

import ClientSelect from 'domain/Coaching/Components/ClientSelect'
import { NavItem } from 'domain/Navigation/Components/Links'

const CoachingClientNavigation = () => {
  const impersonateData = useImpersonate((state) => state.impersonateData)

  return (
    <Box
      display="flex"
      position="sticky"
      zIndex={2}
      paddingX="4"
      paddingY="2"
      background="gray.50"
      borderBottomWidth="1px"
    >
      <HStack spacing="6" alignItems="center">
        <ClientSelect {...{ impersonateData }} />
        {impersonateData.impersonating && (
          <>
            <Box height="30px">
              <Divider orientation="vertical" background="gray.400" width="1px" />
            </Box>

            <Link
              as={NavLink}
              to="/planner"
              display="flex"
              alignItems="start"
              color="gray.500"
              _activeLink={{ color: 'black' }}
              _hover={{ textDecoration: 'none', color: 'brand.500' }}
              end
            >
              Planner
            </Link>

            <Link
              as={NavLink}
              to="/workouts"
              display="flex"
              alignItems="start"
              color="gray.500"
              _activeLink={{ color: 'black' }}
              _hover={{ textDecoration: 'none', color: 'brand.500' }}
              end
            >
              Workouts
            </Link>

            <Menu>
              <MenuButton
                as={Button}
                p="0"
                color="gray.500"
                background="none"
                _active={{ background: 'none' }}
                _focus={{ background: 'none' }}
                _hover={{
                  background: 'none',
                  textDecoration: 'none',
                  color: 'brand.500',
                }}
                iconSpacing="1"
                rightIcon={<Icon as={ChevronDown} w={4} h={4} strokeWidth="1px" />}
              >
                Performance
              </MenuButton>
              <Portal>
                <MenuList zIndex="21" p="1">
                  <MenuItem as={NavLink} to="/metrics" _activeLink={{ color: 'black' }} p="2">
                    Metrics
                  </MenuItem>
                  <MenuItem as={NavLink} to="/goals" _activeLink={{ color: 'black' }} p="2">
                    Goals
                  </MenuItem>
                  <MenuItem as={NavLink} to="/tests" _activeLink={{ color: 'black' }} p="2">
                    Tests
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          </>
        )}
      </HStack>
    </Box>
  )
}

export default CoachingClientNavigation
