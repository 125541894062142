import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Box, Button, FormControl, FormHelperText, Input, Link, Text } from '@chakra-ui/react'

import securedApi from 'backend/axios'

import { useStateManager } from 'hooks'

import Notify from 'components/Notification'

const DeleteAccount = () => {
  const navigate = useNavigate()

  const { clearAll } = useStateManager()

  const [enableText, setEnableText] = useState('')

  const WOLFGANG = 'wolfgang'

  const handleDelete = () => {
    securedApi
      .delete('/signup')
      .then(() => {
        Notify({ content: 'Account has been deleted.', type: 'success' })

        clearAll()

        navigate('/')
      })
      .catch(() => {
        Notify({
          content:
            'There was an error deleting your account. Please contact support if this issue continues',
          type: 'error',
        })
      })
  }

  const onChangeHandler = (event) => {
    const { value } = event.currentTarget
    setEnableText(value)
  }

  const enableAction = enableText === WOLFGANG

  return (
    <Box>
      <Text color="red" fontWeight="bold">
        This action is permanent and can't be undone.
      </Text>
      <Text color="red" fontWeight="bold">
        All of your data will be deleted.
      </Text>

      <Box pt={4} pb={4}>
        <Text>
          Consider{' '}
          <Link as={NavLink} to="/settings/account">
            exporting your data
          </Link>{' '}
          section before proceeding.
        </Text>
        <Text>Once you click that red button all of your data will be gone.</Text>
      </Box>

      <FormControl>
        <FormHelperText>
          Enter{' '}
          <Text display="inline" fontWeight="bold">
            "wolfgang"
          </Text>{' '}
          below to enable the delete button.
        </FormHelperText>

        <Input
          name="enableText"
          type="text"
          value={enableText}
          onChange={(event) => onChangeHandler(event)}
          required
          my="4"
          maxW="400px"
        />
      </FormControl>

      <Button
        onClick={(event) => handleDelete(event)}
        isDisabled={!enableAction}
        colorScheme="red"
        type="submit"
      >
        Delete account
      </Button>
    </Box>
  )
}

export default DeleteAccount
