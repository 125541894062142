import { useQuery } from 'hooks'
import LoadingSpinner from 'components/LoadingSpinner'

import ClimbStrongMembershipPage from './ClimbStrongMembership'
import DefaultPlansPage from './DefaultPlansPage'

// includes membership-climbstrong-monthly and membership-climbstrong-yearly
const CLIMB_STRONG_MEMBERSHIP = 'membership-climbstrong'

const Plans = () => {
  // bit clumsy, perhaps membership_provider could be in a global state?
  // at least this guarantees immediate updates
  const { data: purchasesData, isLoading: isLoadingPurchases } = useQuery('api/v1/purchases')
  const { data: accountsData, isLoading: isLoadingAccounts } = useQuery('accounts/status')

  if (isLoadingPurchases || isLoadingAccounts) {
    return <LoadingSpinner />
  }

  const hasPurchasedPlans = !!purchasesData.length

  const hasClimbStrongMembership =
    accountsData.membership_provider?.startsWith(CLIMB_STRONG_MEMBERSHIP)

  if (hasClimbStrongMembership) {
    return <ClimbStrongMembershipPage />
  }

  return <DefaultPlansPage {...{ hasPurchasedPlans }} />
}

export default Plans
